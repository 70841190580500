.not-found {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
